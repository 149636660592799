import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/home/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "about-us" */ '../views/about-us/index.vue')
  },
  {
    path: '/software-and-services',
    name: 'software-and-services',
    component: () => import(/* webpackChunkName: "software-and-services" */ '../views/software-and-services/index.vue')
  },
  {
    path:"/intelligent-hardware",
    name:"/intelligent-hardware",
    component: () => import(/* webpackChunkName: "intelligent-hardware" */ '../views/intelligent-hardware/index.vue')
  },
  {
    path: '/successful-experience',
    name: 'successful-experience',
    component: () => import(/* webpackChunkName: "successful-experience" */ '../views/successful-experience/index.vue')
  },
  {
    path: '/news-and-information',
    name: 'news-and-information',
    component: () => import(/* webpackChunkName: "news-and-information" */ '../views/news-and-information/index.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "contact-us" */ '../views/contact-us/index.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
